
import BButton from 'buetify/lib/components/button/BButton';
import BField from 'buetify/lib/components/form/field';
import BNumberInput from 'buetify/lib/components/form/numberInput/BNumberInput';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'customization-example',
	components: {
		BField,
		BNumberInput,
		BButton
	}
});
