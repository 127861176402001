
import BField from 'buetify/lib/components/form/field';
import BNumberInput from 'buetify/lib/components/form/numberInput/BNumberInput';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'simple-example',
	components: {
		BField,
		BNumberInput
	},
	setup() {
		return {
			number: shallowRef(0),
			numberPlaceholder: shallowRef<number | undefined>(undefined)
		};
	}
});
